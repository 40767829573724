import './App.css';
import PageRoutes from "./component/pageroutes/pageroutes";

function App() {
  return (
    <div className="App">
      <PageRoutes/>
    </div>
  );
}

export default App;
