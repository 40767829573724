import React from "react";
import { BrowserRouter as Routers, Route, Routes } from "react-router-dom";
import Footer from "../common/footer/footer";
import Header from "../common/header/header";
import Homepage from "../homepage/homepage";

export default function PageRoutes() {
    return (
        <>
            <Routers>
                <Header/>
                <Routes>
                    {/*Application Start*/}
                    <Route path={"/"} element={<Homepage />} />

                    {/*Application End*/}
                    <Route path={"*"} element={<h1>Not Found</h1>} />
                </Routes>
                <Footer />
            </Routers>
        </>
    );
}